import Vue from 'vue'
import Vuex from 'vuex'
const axios = require('axios').default;

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    searchResult: {
      type: 'list',
      data: [],
    },
    searchHistory: [],
    searchPopular: [],
    lettersList: [],
    loginedUser: true,
  },
  getters: {
    getSearchResult: state => {
      return state.searchResult
    },
    getSearchHistory: state => {
      return state.searchHistory
    },
    getSearchPopular: state => {
      return state.searchPopular
    },
    getLettersList: state => {
      return state.lettersList
    },
    getLoginedUser: state => {
      return state.loginedUser
    }
  },
  mutations: {
    SET_SEARCH_RESULT (state, payload) {
      state.searchResult = payload
    },
    SET_SEARCH_HISTORY (state, payload) {
      state.searchHistory = payload
    },
    SET_SEARCH_POPULAR (state, payload) {
      state.searchPopular = payload
    },
    SET_LETTERS_LIST (state, payload) {
      state.lettersList = payload
    },
    SET_LOGINED_USER (state, payload) {
      state.loginedUser = payload
    }
  },
  actions: {
    loadSearchResult({ commit }, payload) {
      axios
        .get('/api/search', {
          params: {
            q: payload,
            page: 1,
            per_page_count: 4
          }
        })
        .then(response => {
          commit('SET_SEARCH_RESULT', response.data.data)
        })
        .catch(error => {
          console.log(error)
        })
    },
    loadSearchHistory({ commit }) {
      axios
        .get('/api/history')
        .then(response => {
          commit('SET_SEARCH_HISTORY', response.data.data)
        })
        .catch(error => {
          console.log(error)
        })
    },
    loadSearchPopular({ commit }) {
      axios
        .get('/api/popular')
        .then(response => {
          commit('SET_SEARCH_POPULAR', response.data.data)
        })
        .catch(error => {
          console.log(error)
        })
    },
    loadLettersList({ commit }) {
      axios
        .get('/api/brands')
          .then(response => {
            let responseData = response.data.data;
            let resultArray = [];
            responseData.forEach(element => {
              const brandObj = {
                id: element.id,
                title: element.name,
                link: element.link,
                active: element.is_favorite
              };
              const resultLetterObj = {
                letter: element.name[0],
                brands: [ brandObj ]
              };

              if (resultArray.length === 0) {
                resultArray.push(resultLetterObj);
              } else {
                let flag = false;

                resultArray.forEach((el, index)=> {
                  if (el.letter == resultLetterObj.letter) {
                    flag = index;
                  }
                });

                if (flag === false) {
                  resultArray.push(resultLetterObj);
                } else {
                  resultArray[flag].brands.push(
                    brandObj
                  );
                }
              }            
            });
            commit('SET_LETTERS_LIST', resultArray)
          })
          .catch(error => {
            console.log(error)
          });
    },
    setLoginedUser({ commit }, payload) {
      commit('SET_LOGINED_USER', payload);
    }
  }
})
