<template>
  <div class="sign-app-wrapper">
    <slot :open="openModal">
      <a class="sign-app-btn" @click="openModal">
        <span>Войти</span>
      </a>
    </slot>
    <ModalSignWrapper v-bind="$attrs" :phone="phone" v-on="listeners"/>
  </div>
</template>

<script>
import ModalSignWrapper from '@/components/modalSign/ModalSignWrapper.vue'

export default {
  inheritAttrs: false,
  name: 'sign-app',
  components: {
    ModalSignWrapper
  },

  props: {
    phone: String
  },

  data: () => ({
    listeners: null
  }),

  created() {
    const { confirm = () => {
      location.reload()
    }, ...listeners } = this.$listeners

    this.listeners = {
      ...listeners,
      confirm: confirm
    }
  },

  mounted() {
    this.$store.dispatch("setLoginedUser", false);
  },
  methods: {
    openModal(event) {
      if (typeof event.preventDefault === 'function') {
        event.preventDefault()
      }

      this.$bvModal.show('sign-app-modal')
    }
  }
}
</script>

<style lang="scss">
  .sign-app-btn {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    padding: 5px 22px;
    background-color: #CD993D;
    transition: 0.3s linear;

    span {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #FFFFFF;
    }

    &:hover {
      background-color: #825f22;
    }
  }

  #sign-app-modal {
    padding: 0px !important;
  }

  .vue-modal {
    .modal-close {
      position: absolute;
      right: -19px;
      top: -19px;
      transform: translate(100%, -100%);
      cursor: pointer;
      path {
        transition: 0.3s linear;
      }
      &:hover {
        path {
          fill: white;
        }
      }
      @media screen and (max-width: 575px) {
        display: none;
      }
    }

    &.fade .modal-dialog {
      transform: translate(0px, -25px);
    }

    &.show .modal-dialog {
      transform: translate(0px, 0px);
    }

    .modal-body {
      padding: 0px !important;
    }

    .modal-content {
      width: 100%;
      padding: 0px !important;
      border: none !important;
    }

    .modal-dialog {
      width: 100%;
      margin: 0 auto;
    }

    .modal-dialog-centered {
      display: flex;
      align-items: center;
      min-height: 100%;
    }

    @media (min-width: 576px) {
      .modal-dialog-centered {
        min-height: 100%;
      }

      .modal-dialog {
        width: 415px;
      }
    }

    @media (min-width: 768px) {
      .modal-dialog {
        width: 415px;
      }
    }
  }

  .modal-backdrop {
    background: rgba(24, 26, 29, 0.95) !important;
  }

  @media (max-width: 575px) {
    .sign-content {
      min-height: 100vh;
    }
  }
</style>
