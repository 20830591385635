<template>
  <div
    ref="searchContentMobile"
    class="search-content search-content_burger"
    :class="focused ? 'active' : ''"
  >
    <form
      action=""
      method=""
      @submit.prevent="goToSearchPage"
      class="search-content__form"
    >
      <input
        type="text"
        ref="searchInputBurger"
        placeholder="Поиск"
        v-model="searchValue"
        @input="loadSearch"
        @focus="openMobileSearch"
      >
      <button 
        type="submit" 
        :class="searchValue.length > 2 ? 'active' : ''"
      >
        <InputSearchIcon />
      </button>
    </form>

    <div 
      v-show="focused" 
      class="search-content__wrapper"
    >

      <ul
        v-if="searchValue.length < 3 && searchHistory.length"
        class="search-history__list"
      >
        <li
          v-for="el in searchHistory"
          :key="el"
          class="search-history__list-el"
        >
          <span @click.prevent="pastToInput($event.target.innerText)">{{ el }}</span>
        </li>
      </ul>

      <SearchPopular
        v-if="searchValue.length < 3 && searchPopular.length && searchHistory.length === 0"
        :search-list="searchPopular"
      >
        <ul class="search-history__list">
          <li
            v-for="el in searchPopular"
            :key="el"
            class="search-history__list-el"
          >
            <span @click.prevent="pastToInput($event.target.innerText)">{{ el }}</span>
          </li>
        </ul>
      </SearchPopular>

      <SearchResult 
        v-if="searchValue.length > 2 && searchResult.type === 'list' && searchResult" 
        :search-list="searchResult.data"
      />

      <div 
        v-show="searchValue.length > 2 && searchResult.type === 'cards' && searchResult" 
        class="search-content__cards"
      >
        <SearchResultCard
          v-for="searchCard in searchResult.data"
          :key="searchCard.id"
          :search-card="searchCard"
        />
      </div>

      <p 
        v-if="searchValue.length > 2 && searchResult.data.length === 0"
        class="search-content__notfound"
      >
        Нет результатов
      </p>      
    </div>

    <div 
      v-show="focused" 
      @click="closeMobileSearch" 
      class="search-content__overlay"
    >
    </div>
  </div>
</template>

<script>
import SearchPopular from '@/components/headerSearch/SearchPopular.vue'
import SearchResult from '@/components/headerSearch/SearchResult.vue'
import SearchResultCard from '@/components/headerSearch/SearchResultCard.vue'
import InputSearchIcon from '@/assets/svg/burgerSearchIcon.svg';

export default {
  name: 'BurgerSearch',
  components: {
    SearchPopular,
    SearchResult,
    SearchResultCard,
    InputSearchIcon
  },
  data() {
    return {
      searchValue: '',
      focused: false,
      searchTimer: {
        timerFunction: null,
        timerTime: 500,
      },
    }
  },
  computed: {
    searchResult() {
      return this.$store.getters.getSearchResult
    },
    searchHistory() {
      return this.$store.getters.getSearchHistory
    },
    searchPopular() {
      return this.$store.getters.getSearchPopular
    }
  },
  mounted() {
    this.pastSearchedValue()
  },
  methods: {
    goToSearchPage() {
      if (this.searchValue.length > 2) {
        window.location.href = `/search/?q=${this.searchValue}`
      }
    },
    loadSearch() {
      const thisSearchValue = this.searchValue

      clearTimeout(this.searchTimer.timerFunction);
      this.searchTimer.timerFunction = setTimeout(() => {
        if (thisSearchValue.length > 2) {
          this.$store.dispatch('loadSearchResult', thisSearchValue)
        }
      }, this.searchTimer.timerTime);
    },
    openMobileSearch() {
      this.focused = true
      this.$refs.searchContentMobile.style.height = document.querySelector('.page-header__menu-wrapper').scrollHeight + 'px'
      this.$store.dispatch('loadSearchHistory')
      this.$store.dispatch('loadSearchPopular')
    },
    closeMobileSearch() {
      this.focused = false
      this.$refs.searchContentMobile.style.height = 'auto'
    },
    pastToInput(text) {
      this.$refs.searchInputBurger.value = text
      this.searchValue = text
      this.$store.dispatch('loadSearchResult', text)
    },
    pastSearchedValue() {
      const location = document.location;
      if (location.pathname === '/search/') {
        const searched = new URLSearchParams(document.location.search.substring(1))
        const searchedValue = searched.get("q")
        this.searchValue = searchedValue
        this.loadSearch()
      }
    }
  }
}
</script>

<style lang="scss">
  .search-content {
    &_burger {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
      padding: 0;
      background-color: transparent;

      @media (min-width: 1200px) {
        display: none;
      }

      .search-content__form {
        padding: 15px 16px;
        margin-bottom: 0px;
        z-index: 10;
        background-color: #2D2E33;
        transition: background-color 0.3s;

        @media (min-width: 768px) {
          padding: 24px 20px 20px;
        }

        input {
          font-size: 13px;
          line-height: 20px;
          padding: 0 35px 13px 0;
          height: 33px;
          color: #fff;
        }

        button {
          top: 15px;
          right: 16px;

          svg {
            circle {
              stroke: #9A9BA0;
              transition: 0.3s linear;
            }
            path {
              fill: #9A9BA0;
              transition: 0.3s linear;
            }
          }

          @media (min-width: 768px) {
            top: 24px;
            right: 20px;
          }
        }
      }

      &.active {
        height: 100%;
      }
    }

    &__wrapper {
      padding: 10px 16px 30px;
      position: absolute;
      top: 63px;
      left: 0px;
      width: 100%;
      background-color: #2D2E33;
      z-index: 10;
      transition: background-color 0.3s;

      @media (min-width: 768px) {
        padding: 5px 20px 30px;
        top: 77px;
      }
    }

    &__overlay {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.8);
      z-index: 9;
    }

    &.active {
      .search-content__form, .search-content__wrapper {
        background-color: #fff;
      }
      .search-content__form {
        input {
          color: #25262a;
        }
        button {
          svg {
            circle {
              stroke: #25262A;
            }
            path {
              fill: #25262A;
            }
          }
        }
      }
    }
  }
</style>