<template>
  <ul class="search-popular__list">
    <li
      v-for="el in searchList"
      :key="el.name"
      class="search-popular__list-el">
      <a :href="el.href" v-html="el.name"></a>
      <p
        v-if="el.sections.length > 0"
        class="search-popular__list-category">
        <a
          v-for="section in el.sections"
          :key="section.title"
          :href="section.link">
          {{ section.title }}
        </a>
      </p>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'SearchList',
  props: [ 'searchList' ],
}
</script>

<style lang="scss">
  .search-popular__list {
    &-el {
      &>a {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 30px;
        letter-spacing: -0.015em;
        color: #25262A;
        transition: 0.3s linear;
        display: block;

        &:hover {
          opacity: 0.6;
        }
      }
    }

    &-category {
      a {
        font-weight: 400;
        opacity: 0.7;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.2px;
        transition: 0.3s linear;
        font-family: "Bebas Neue", Helvetica, Arial, sans-serif;
        color: #25262A;

        &:hover {
          opacity: 0.4;
        }

        &:after {
          content: ', ';
        }

        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }
  }
</style>