<template>
  <transition name="slide">
    <div v-if="isShowing" class="auth-action-popup">
      <div class="auth-action-popup__text">
        <a href="#modal-auth">Авторизируйся</a>, чтобы получить скидку
      </div>
      <svg class="auth-action-popup__close" @click="isShowing = false" width="18" height="18" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.5423 17.9932L35.469 3.05979C35.6372 2.89432 35.7707 2.69726 35.8619 2.48002C35.9531 2.26278 36 2.02968 36 1.79425C36 1.55881 35.9531 1.32572 35.8619 1.10848C35.7707 0.891236 35.6372 0.694171 35.469 0.528706C35.3028 0.361221 35.1048 0.228246 34.8866 0.137489C34.6684 0.0467328 34.4343 0 34.1978 0C33.9614 0 33.7272 0.0467328 33.509 0.137489C33.2908 0.228246 33.0929 0.361221 32.9267 0.528706L18 15.444L3.0733 0.528706C2.9071 0.361221 2.70917 0.228246 2.49097 0.137489C2.27277 0.0467328 2.03865 0 1.80217 0C1.5657 0 1.33157 0.0467328 1.11337 0.137489C0.895172 0.228246 0.697237 0.361221 0.531041 0.528706C0.362816 0.694171 0.229254 0.891236 0.138097 1.10848C0.0469392 1.32572 0 1.55881 0 1.79425C0 2.02968 0.0469392 2.26278 0.138097 2.48002C0.229254 2.69726 0.362816 2.89432 0.531041 3.05979L15.4577 17.9932L0.531041 32.9265C0.362816 33.092 0.229254 33.2891 0.138097 33.5063C0.0469392 33.7236 0 33.9566 0 34.1921C0 34.4275 0.0469392 34.6606 0.138097 34.8779C0.229254 35.0951 0.362816 35.2922 0.531041 35.4576C0.876062 35.8192 1.33004 36 1.80217 36C2.25615 36 2.71012 35.8192 3.0733 35.4576L18 20.5423L32.9267 35.4576C33.2717 35.8192 33.7257 36 34.1978 36C34.6518 36 35.1058 35.8192 35.469 35.4576C35.6372 35.2922 35.7707 35.0951 35.8619 34.8779C35.9531 34.6606 36 34.4275 36 34.1921C36 33.9566 35.9531 33.7236 35.8619 33.5063C35.7707 33.2891 35.6372 33.092 35.469 32.9265L20.5423 17.9932Z"/>
      </svg>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'AuthActionPopup',

  data:(vm) =>( {
    isShowing: !vm.$store.state.loginedUser
  }),
}
</script>

<style scoped lang="scss">
.auth-action-popup {
  display: flex;
  position: fixed;
  height: 60px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background: #18181D;
  bottom: 0;
  z-index: 200;
  color: white;
  font-size: 16px;
  line-height: 1;
  padding: 15px 20px;
  align-items: center;

  @media screen and (max-width: 599px) {
    width: 100%;
  }

  @media screen and (min-width: 600px){
    height: 70px;
    bottom: 30px;
    justify-content: space-between;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    font-size: 22px;
    min-width: 460px;
  }

  @media screen and (min-width: 600px) and (max-width: 1439px){
    left: 50%;
    transform: translateX(-50%);
  }

  @media screen and (min-width: 1440px){
    left: 120px;
  }


  &__close {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
    fill: white;
    path {
      transition: 0.3s linear;
    }

    @media screen and (min-width: 600px){
      position: unset;
    }
  }

  &__text {
    @media screen and (min-width: 600px) {
      position: relative;
      padding-left: 60px;
      padding-right: 40px;
      &:before {
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: inline-block;
        content: "";
        width: 40px;
        height: 40px;
        background-image: url("~@/assets/img/3DIcon_Xmas.png");
      }
    }
  }
}

.slide-enter-active, .slide-leave-active {
  transition: transform .5s ease-in;
}
.slide-enter, .slide-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  transform: translateY(1000%);

  @media screen and (min-width: 600px){
    transform: translateX(-1000%);
  }
}
</style>
