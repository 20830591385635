<template>
<div class="dolyami-scheme">
  <ul class="dolyami-scheme__stepper">
    <li class="dolyami-scheme__step" v-for="(payment, index) in payments" :key="index">
      <h4 class="dolyami-scheme__step-amount">{{ payment }} <slot name="unit"><i class="rouble" itemprop="priceCurrency" content="RUB">a</i></slot></h4>
      <p class="dolyami-scheme__step-date">{{ steps[index] }}</p>
      <circular-progress class="dolyami-scheme__step-progress"/>
    </li>
  </ul>
  <ul class="dolyami-scheme__feature-list">
    <li class="dolyami-scheme__feature">Оплачивайте покупки частями — по 25% каждые две недели</li>
    <li class="dolyami-scheme__feature">Никаких дополнительных платежей — как обычная оплата картой</li>
  </ul>
</div>
</template>

<script>
import CircularProgress from '@/components/CircularProgress'
export default {
  name: 'DolyamiScheme',
  components: {CircularProgress},
  props: {
    payments: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    steps () {
      // TODO отрефачить в нормальный метод
      return ['Сегодня', 'Через 2 недели', 'Через 4 недели', 'Через 6 недель']
    }
  }
}
</script>

<style scoped lang="scss">
.dolyami-scheme {
  background-color: #F6F7F8;
  padding: 10px 20px;
  width: 100%;
  font-family: 'Inter', sans-serif;
  &__stepper {
    --spacing: 6px;
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width:480px) {
      flex-direction: column;
    }
  }

  &__step {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;

    @media screen and (max-width:480px) {
      gap: 40px;
      flex-direction: row;
    }

    & &-progress {
      --size: 18px;

      @media screen and (max-width:480px) {
       order: -1;
      }
    }

    &:last-child &-progress:before {
      line-height: var(--size);
      content: "\2713";
      color: green;
    }

    &:not(:last-child) &-progress:before {
      content: " ";
      border-radius: 50%;
      width: calc(var(--size)/2);
      height: calc(var(--size)/2);
      background-color: #000;
    }

    &:not(:last-child) &-progress:after {
        content: " ";
        height: 2px;
        background: #C7C9CC;
        position: absolute;
        width: calc(100% - var(--size) - calc(var(--spacing) * 2));
        left: calc(calc(var(--size) + var(--spacing)));

        @media screen and (max-width:480px) {
          top: var(--size);
          height: 100%;
          width: 2px;
          left: calc(var(--size) / 2);
        }
    }

    &:nth-child(1) &-progress {
      --value: 25;
    }

    &:nth-child(2) &-progress {
      --value: 50;
    }

    &:nth-child(3) &-progress{
      --value: 75;
    }

    &:nth-child(4) &-progress{
      --value: 100;
    }

    &-date {
      margin: 2px 0 16px;
    }
  }

  &__feature {
    margin: 8px 0;
    text-align: left;
    padding-left: 1rem;
    &::marker {
      content: "\2713";
      color: green;
    }
    &-list {
      margin-top: 20px;
    }
  }
}
</style>
