<template>
  <div class="search-popular">
    <p class="search-popular__title">популярное</p>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'SearchPopular',
  props: [ 'searchList' ],
}
</script>

<style lang="scss">
  .search-popular {
    &__title {
      font-family: "Bebas Neue", Helvetica, Arial, sans-serif;
      text-transform: uppercase;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      letter-spacing: 0.05em;
      color: #25262A;
      margin-bottom: 15px;
    }
  }
</style>