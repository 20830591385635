<template>
  <div class="search-result">
    <SearchList :search-list="searchList"/>
  </div>
</template>

<script>
import SearchList from '@/components/headerSearch/SearchList.vue'

export default {
  components: { SearchList },
  name: 'SearchResult',
  props: [ 'searchList' ],
}
</script>

<style lang="scss">
  .search-result {
  }
</style>