<template>
  <ModalSign @confirm="handleConfirm" v-model="modalType"/>
</template>

<script>
import ModalSign from '@/components/modalSign/ModalSign.vue'

export default {
  name: 'AuthPageApp',
  components: {ModalSign},

  data:() => ({
    modalType: 'phone'
  }),

  methods: {
    handleConfirm (confirm) {
      if (!confirm) return;

      location.href = '/'
    }
  }
}
</script>
