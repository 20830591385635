<template>
  <div class="search-app-wrapper">
    <a v-b-modal.search-app-modal class="search-app-btn">
      <HeaderSearchIcon />
    </a>

    <b-modal 
      modal-class="vue-modal" 
      id="search-app-modal" 
      hide-footer 
      hide-header 
      @show="showModal"
      @hidden="hideModal">
      <HeaderSearch/>
    </b-modal>
  </div>
</template>

<script>
import HeaderSearch from '@/components/headerSearch/HeaderSearch.vue'
import HeaderSearchIcon from '@/assets/svg/headerSearchIcon.svg';

export default {
  name: 'sign-app',
  components: {
    HeaderSearch,
    HeaderSearchIcon
  },
  methods: {
    showModal() {
      if (this.elWidth('windowClientWidth') > 1200) {
        document.querySelector('.page-header').style.width = `calc(90% - ${this.elWidth('windowScrollWidth')}px)`;
      } else {
        document.querySelector('.page-header').style.width = `calc(100% - ${this.elWidth('windowScrollWidth')}px)`;
      }
    },
    hideModal() {
      document.querySelector('.page-header').removeAttribute('style');
    },
    elWidth(elWidth) {
      const windowClientWidth = document.documentElement.clientWidth
      
      if (elWidth === "windowClientWidth") {
        return windowClientWidth
      } else if (elWidth === "windowScrollWidth") {
        const windowInnerWidth = window.innerWidth;
        const windowScrollWidth = windowInnerWidth - windowClientWidth
        return windowScrollWidth
      }
    }
  }
}
</script>

<style lang="scss">  
  .search-app-btn {
    margin-right: 25px;
    display: none;

    svg {
      circle {
        transition: 0.3s linear;
      }
      path {
        transition: 0.3s linear;
      }
    }
    
    &:hover {
      svg {
        circle {
          stroke: #cd993d;
        }
        path {
          fill: #cd993d;
        }
      }
    }

    @media (min-width: 1200px) {
      display: block;
    }
  }

  .search-history__list {
    &-el {
      span {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 30px;
        letter-spacing: -0.015em;
        color: #25262A;
        transition: 0.3s linear;
        cursor: pointer;

        &:hover {
          opacity: 0.6;
        }
      }
    }
  }

  #search-app-modal {
    .modal-dialog {
      width: 100%;
      max-width: 1296px;
    }
  }
</style>
