<template>
  <b-modal
    v-model="isShowing"
    modal-class="vue-modal"
    id="sign-app-modal"
    centered
    hide-footer
    hide-header
    lazy
    @show="showModal"
    @hidden="hideModal">
    <CloseModalBigIcon @click="closeModalSign" class="modal-close"/>
    <modal-sign
        v-model="modalType"
        v-bind="$attrs"
        :phone="phone"
        v-on="$listeners"
        @close-modal-sign="closeModalSign"
    />
  </b-modal>
</template>

<script>
import ModalSign from '@/components/modalSign/ModalSign.vue'
import CloseModalBigIcon from '@/assets/svg/closeModalBigIcon.svg';

const modalId = '#modal-auth';

export default {
  name: 'sign-app-wrapper',
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: 'phone'
    },
    phone: {
      type: String,
      default: ''
    }
  },
  data:(vm) => ({
    isShowing: window.location.hash === modalId,
    modalType: vm.type
  }),
  components: {
    ModalSign,
    CloseModalBigIcon
  },
  created ()  {
    window.addEventListener('hashchange', this.handleHash, false);
  },
  beforeDestroy () {
    window.removeEventListener('hashchange', this.handleHash);
  },
  methods: {
    handleHash () {
      this.isShowing = window.location.hash === modalId
    },
    closeModalSign() {
      this.$bvModal.hide('sign-app-modal');
      history.pushState(null, '', ' ');
      this.modalType = this.$options.data.apply(this).modalType
    },
    showModal() {
      if (this.elWidth('windowClientWidth') > 1200) {
        document.querySelector('.page-header').style.width = `calc(90% - ${this.elWidth('windowScrollWidth')}px)`;
      } else {
        document.querySelector('.page-header').style.width = `calc(100% - ${this.elWidth('windowScrollWidth')}px)`;
      }
    },
    hideModal() {
      document.querySelector('.page-header').removeAttribute('style');
    },
    elWidth(elWidth) {
      const windowClientWidth = document.documentElement.clientWidth

      if (elWidth === "windowClientWidth") {
        return windowClientWidth
      } else if (elWidth === "windowScrollWidth") {
        const windowInnerWidth = window.innerWidth;
        const windowScrollWidth = windowInnerWidth - windowClientWidth
        return windowScrollWidth
      }
    }
  }
}
</script>

<style lang="scss">
  .sign-app-btn {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    padding: 5px 22px;
    background-color: #CD993D;
    transition: 0.3s linear;

    span {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #FFFFFF;
    }

    &:hover {
      background-color: #825f22;
    }
  }

  #sign-app-modal {
    padding: 0px !important;
  }

  .vue-modal {
    .modal-close {
      position: absolute;
      right: -19px;
      top: -19px;
      transform: translate(100%, -100%);
      cursor: pointer;
      path {
        transition: 0.3s linear;
      }
      &:hover {
        path {
          fill: white;
        }
      }
      @media screen and (max-width: 575px) {
        display: none;
      }
    }

    &.fade .modal-dialog {
      transform: translate(0px, -25px);
    }

    &.show .modal-dialog {
      transform: translate(0px, 0px);
    }

    .modal-body {
      padding: 0px !important;
    }

    .modal-content {
      width: 100%;
      padding: 0px !important;
      border: none !important;
    }

    .modal-dialog {
      width: 100%;
      margin: 0 auto;
    }

    .modal-dialog-centered {
      display: flex;
      align-items: center;
      min-height: 100%;
    }

    @media (min-width: 576px) {
      .modal-dialog-centered {
        min-height: 100%;
      }

      .modal-dialog {
        width: 415px;
      }
    }

    @media (min-width: 768px) {
      .modal-dialog {
        width: 415px;
      }
    }
  }

  .modal-backdrop {
    background: rgba(24, 26, 29, 0.95) !important;
  }

  @media (max-width: 575px) {
    .sign-content {
      min-height: 100vh;
    }
  }
</style>
