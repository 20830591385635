<template>
  <form
    :action="url"
    :method="method"
    @submit.prevent="signInLoginSubmit"
    class="sign-in-login-form"
    novalidate
  >
    <p class="sign-content__text">
      Отправим на указанный адрес ссылку для восстановления доступа. Если ваш адрес есть в базе, мы отправим инструкцию по восстановлению.
    </p>

    <div class="sign-in-login-form__wrapper">
      <label class="input-block" :class="{ 'input-block__error': $v.email.$error }">
        <span class="input-block__label">email</span>
        <input class="input-block__input" type="email" v-model.trim="email" name="email">
        <span class="input-block__message">{{ wrongMessageEmail }}</span>
      </label>
    </div>
    <div class="sign-action">
      <button type="submit" class="btn btn--gray-dark">Восстановить</button>
      <a
          href="#"
          @click.prevent="$emit('go-to-phone-form')"
          class="sign-method__link"
      >
        Получить SMS
      </a>
    </div>
    <p v-if="status" class="sign-content__text status">{{ status }}</p>
  </form>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import axios from 'axios'

const emailWrongMessages = {
      required: 'Заполните поле',
      email: 'Введите корректный email'
    }

export default {
  name: 'SignInLoginForm',
  data() {
    return {
      status: '',
      url: '/',
      method: 'POST',
      email: '',
    }
  },
  computed: {
    wrongMessageEmail() {
      if (!this.$v.email.email) {
        return emailWrongMessages.email;
      }

      if (!this.$v.email.required) {
        return emailWrongMessages.required;
      }
      return false
    },
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  methods: {
    signInLoginSubmit(event) {
      this.$v.$touch()
      this.status = ''
      const data = new FormData(event.target)

      if (!this.$v.$error) {
        axios
          .post('/local/ajax/access_recovery.php', data)
          .then(this.emailPwdResponse)
      }
    },
    emailPwdResponse(response) {
      this.$v.$reset();
      this.email = ''
      window.vkPixel.completeRegistration()
      this.status = response.data
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .sign-in-login-form {
    padding-bottom: 20px;
    border-bottom: 3px solid #25262A;
    .btn {
      cursor: pointer;
      margin-top: 20px;
      transition: 0.3s linear;
      position: relative;
      &:disabled {
        opacity: 0.5;
        cursor: default;
        &:hover {
          border-color: #25262a;
          background-color: #25262a;
        }
      }
    }
  }

  .input-block {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 21px;
    &:last-child {
      margin-bottom: 0;
    }
    &__label {
      font-family: Georgia, "Times New Roman", Times, serif;
      font-size: 1rem;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #9A9BA0;
      margin-bottom: 10px;
    }
    &__input {
      font-family: Georgia, "Times New Roman", Times, serif;
      font-size: 1.6rem;
    }
    &__message {
      position: absolute;
      bottom: -1px;
      right: 0;
      transform: translateY(100%);
      margin-bottom: 0;
      font-size: 10px;
      display: none;
      color: #ed6c32;
    }
    &__error {
      .input-block__input {
        border-color: #ed6c32;
      }
      .input-block__message {
        display: block;
      }
    }
  }
  .wrong-back__message {
    position: absolute;
    bottom: -5px;
    right: 0;
    transform: translateY(100%);
    margin-bottom: 0;
    font-size: 11px;
    display: none;
    color: #ed6c32;
    &.active {
      display: block;
    }
  }

  .status {
    color: #CD993D;
    margin-top: 16px;
  }
</style>
