<template>
  <div class="search-content">
    <form
      action=""
      method=""
      @submit.prevent="goToSearchPage"
      class="search-content__form"
    >
      <input
        type="text"
        ref="searchInputHeader"
        placeholder="Поиск"
        v-model="searchValue"
        @input="loadSearch"
      >
      <button
        type="submit"
        :class="searchValue.length > 2 ? 'active' : ''"
      >
        <InputSearchIcon />
      </button>
    </form>

    <ul
      v-if="searchValue.length < 3 && searchHistory.length"
      class="search-history__list"
    >
      <li
        v-for="el in searchHistory"
        :key="el"
        class="search-history__list-el"
      >
        <span @click.prevent="pastToInput($event.target.innerText)">{{ el }}</span>
      </li>
    </ul>

    <SearchPopular
      v-if="searchValue.length < 3 && searchPopular.length && searchHistory.length === 0"
      :search-list="searchPopular"
    >
      <ul class="search-history__list">
        <li
          v-for="el in searchPopular"
          :key="el"
          class="search-history__list-el"
        >
          <span @click.prevent="pastToInput($event.target.innerText)">{{ el }}</span>
        </li>
      </ul>
    </SearchPopular>

    <SearchResult
      v-if="searchValue.length > 2 && searchResult.type === 'list' && searchResult"
      :search-list="searchResult.data"
    />

    <div
      v-if="searchValue.length > 2 && searchResult.type === 'cards' && searchResult"
      class="search-content__cards"
    >
      <SearchResultCard
        v-for="searchCard in searchResult.data"
        :key="searchCard.id"
        :search-card="searchCard"
      />
    </div>

    <p
      v-if="searchValue.length > 2 && searchResult.data.length === 0"
      class="search-content__notfound"
    >
      Нет результатов
    </p>
  </div>
</template>

<script>
import SearchPopular from '@/components/headerSearch/SearchPopular.vue'
import SearchResult from '@/components/headerSearch/SearchResult.vue'
import SearchResultCard from '@/components/headerSearch/SearchResultCard.vue'
import InputSearchIcon from '@/assets/svg/burgerSearchIcon.svg';

export default {
  name: 'HeaderSearch',
  components: {
    SearchPopular,
    SearchResult,
    SearchResultCard,
    InputSearchIcon
  },
  data() {
    return {
      searchValue: '',
      searchTimer: {
        timerFunction: null,
        timerTime: 500,
      },
    }
  },
  computed: {
    searchResult() {
      return this.$store.getters.getSearchResult
    },
    searchHistory() {
      return this.$store.getters.getSearchHistory
    },
    searchPopular() {
      return this.$store.getters.getSearchPopular
    }
  },
  mounted () {    
    this.$store.dispatch('loadSearchHistory')
    this.$store.dispatch('loadSearchPopular')
    this.pastSearchedValue()
  },
  methods: {
    goToSearchPage() {
      if (this.searchValue.length > 2) {
        window.location.href = `/search/?q=${this.searchValue}`
      }
    },
    loadSearch() {
      const thisSearchValue = this.searchValue

      clearTimeout(this.searchTimer.timerFunction);
      this.searchTimer.timerFunction = setTimeout(() => {
        if (thisSearchValue.length > 2) {
          this.$store.dispatch('loadSearchResult', thisSearchValue)
        }
      }, this.searchTimer.timerTime);
    },
    pastToInput(text) {
      this.$refs.searchInputHeader.value = text
      this.searchValue = text
      this.$store.dispatch('loadSearchResult', text)
    },
    pastSearchedValue() {
      const location = document.location;
      if (location.pathname === '/search/') {
        const searched = new URLSearchParams(document.location.search.substring(1))
        const searchedValue = searched.get("q")
        this.searchValue = searchedValue
        this.loadSearch()
      }
    }
  }
}
</script>

<style lang="scss">
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  #search-app-modal {
    padding-left: 0px !important;
  }

  .search-content {
    padding: 30px;
    z-index: 1;
    background-color: #fff;

    &__form {
      position: relative;
      margin-bottom: 30px;
      
      input {
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.015em;
        color: #25262A;
        padding: 0 35px 3px 0;
        border: none;
        border-bottom: 1px solid #9A9BA0;

        &::placeholder {
          font-style: italic;
          color: #9A9BA0;
        }
      }

      button {
        position: absolute;
        right: 0;
        top: 0;
        padding: 0;
        margin: 0;
        line-height: 0px;
        border: none;
        background-color: transparent;

        svg {
          circle {
            stroke: #9A9BA0;
            transition: 0.3s linear;
          }
          path {
            fill: #9A9BA0;
            transition: 0.3s linear;
          }
        }

        &.active {
          svg {
            circle {
              stroke: #25262A;
            }
            path {
              fill: #25262A;
            }
          }
        }
      }
    }

    &__notfound {
      font-family: 'Inter', sans-serif;
      font-style: italic;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #9A9BA0;
      margin: 0;
    }

    &__cards {
      display: grid;
      grid-template-columns: repeat(1, minmax(297px, 1fr));
      grid-gap: 16px;
      justify-content: space-between;

      @media (min-width: 576px) {
        grid-template-columns: repeat(2, minmax(calc(50% - 8px), calc(50% - 8px)));
      }

      @media (min-width: 768px) {
        grid-template-columns: repeat(2, minmax(297px, 1fr));
      }

      @media (min-width: 968px) {
        grid-template-columns: repeat(3, minmax(297px, 1fr));
      }

      @media (min-width: 1296px) {
        grid-template-columns: repeat(4, minmax(297px, 1fr));
      }
    }
  }
</style>