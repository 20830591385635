<template>
  <form
    ref="form"
    @submit.prevent="goToCodeForm"
    class="sign-in-phone-form"
  >
    <label
      class="input-block"
      :class="errorPhoneMessage.length > 0 ? 'input-block__error' : ''"
    >
      <span class="input-block__label">Телефон</span>
      <input
        type="text"
        name="phone"
        inputmode="tel"
        v-model="signInPhoneForm.phone"
        placeholder="+7 (___) ___-__-__"
        v-mask="{mask: '+7 (999) 999-99-99', showMaskOnHover: false}"
        @input="maskCheck"
        class="input-block__input"
      >
      <p class="input-block__message">{{ errorPhoneMessage }}</p>
    </label>
    <div class="sign-action">
      <button
          type="submit"
          ref="phoneBtn"
          :disabled="loading"
          class="btn btn--gray-dark"
      >
        <span>Получить SMS</span>
      </button>
      <a
          href="#"
          @click.prevent="$emit('go-to-email-form')"
          class="sign-method__link"
      >
        Восстановить доступ
      </a>
    </div>
  </form>
</template>

<script>
export default {
  name: 'SignInPhoneForm',
  props: ['errorPhoneMessage', 'loading'],
  data() {
    return {
      signInPhoneForm: {
        phone: '',
        disableButton: true,
      },
    }
  },
  methods: {
    maskCheck(field) {
      if (field.target.inputmask.isComplete()) {
        this.signInPhoneForm.disableButton = false;
        this.$emit('clear-phone-message');
      } else {
        this.signInPhoneForm.disableButton = true;
      }
    },
    goToCodeForm() {
      this.signInPhoneForm.disableButton = true;
      const formData = new FormData(this.$refs.form);

      setTimeout(() => {
        this.signInPhoneForm.disableButton = false;
      }, 5000);
      this.$emit('go-to-code-form', formData);
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .input-block {
    display: flex;
    flex-direction: column;
    position: relative;
    &__label {
      font-family: Georgia, "Times New Roman", Times, serif;
      font-size: 1rem;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #9A9BA0;
      margin-bottom: 10px;
    }
    &__input {
      font-family: Georgia, "Times New Roman", Times, serif;
      font-size: 1.6rem;
    }
    &__message {
      position: absolute;
      bottom: -1px;
      right: 0px;
      transform: translateY(100%);
      margin-bottom: 0px;
      font-size: 10px;
      display: none;
      color: #ed6c32;
    }
    &__error {
      .input-block__input {
        border-color: #ed6c32;
      }
      .input-block__message {
        display: block;
      }
    }
  }
  .sign-in-phone-form {
    padding-bottom: 20px;
    border-bottom: 3px solid #25262A;
    .btn {
      cursor: pointer;
      margin-top: 20px;
      transition: 0.3s linear;
      &:disabled {
        opacity: 0.5;
        cursor: default;
        &:hover {
          border-color: #25262a;
          background-color: #25262a;
        }
      }
    }
  }
</style>
