<template>
  <div id="profile__edit" class="profile__edit hide col-xs-12 col-sm-9 col-md-9">
    <form
        id="profile-edit" class="profile__edit-form" method="post" :name="name" :action="action"
        enctype="multipart/form-data"
        @submit.prevent="submitForm"
        @reset.prevent="resetForm"
    >
      <ul class="profile__edit-form-list">
        <li class="profile__edit-form-list-item">
          <label for="profile-edit-name" class="profile__edit-form-list-item-title col-xs-12 col-md-4">
            {{ labels.SPA_NAME }}
          </label>
          <div class="profile__edit-form-list-item-input col-xs-12 col-md-8">
            <input
                id="profile-edit-name" type="text" name="NAME" maxlength="50"
                v-model="formData.NAME" required
            />
          </div>
          <div class="clearfix"></div>
        </li>
        <li class="profile__edit-form-list-item">
          <label
              for="profile-edit-date"
              class="profile__edit-form-list-item-title col-xs-12 col-md-4"
          >{{ labels.USER_BIRTHDAY_DT }}</label>
          <div class="profile__edit-form-list-item-input col-xs-12 col-md-8">
            <slot name="calendar-input"/>
          </div>
          <div class="clearfix"></div>
        </li>
        <li class="profile__edit-form-list-item">
          <label for="profile-edit-email" class="profile__edit-form-list-item-title col-xs-12 col-md-4">
            {{ labels.EMAIL }}
          </label>
          <div class="profile__edit-form-list-item-input col-xs-12 col-md-8">
            <input
                id="profile-edit-email" type="email" required name="EMAIL" maxlength="50"
                v-model="formData.EMAIL"
            />
          </div>
          <div class="clearfix"></div>
        </li>
        <li class="profile__edit-form-list-item">
          <label for="profile-edit-phone" class="profile__edit-form-list-item-title col-xs-12 col-md-4">
            {{ labels.USER_PHONE }}
          </label>
          <div class="profile__edit-form-list-item-input col-xs-12 col-md-8">
            <input
                ref="phoneInput"
                data-field-mask="phone"
                data-mask="+7(000)000-00-00"
                placeholder="+7(___)___-__-__"
                id="profile-edit-phone" type="text" name="PERSONAL_PHONE"
                maxlength="255" v-model="formData.PERSONAL_PHONE" required
            />
            <div class="profile__edit-phone-approve">
              <template v-if="approved">
                <span class="profile__phone-status profile__phone-status--verified">
                              <img src="/local/2016/img/svg-icons/check.svg" alt="">
                  {{ labels.PHONE_CONFIRMED }}
                </span>
              </template>
              <template v-else>
                <span class="profile__phone-status profile__phone-status--not-verified">
                                <img src="/local/2016/img/svg-icons/alert.svg" alt="">
                  {{ labels.PHONE_NOT_CONFIRMED }}
                            </span>
                <sign-app
                    type="code"
                    :phone="formData.PERSONAL_PHONE"
                    confirm-action="confirm_phone_code_profile.php"
                    @confirm = 'approved = $event'
                >
                  <template #default="{ open }">
                    <a
                        class="profile__edit-phone-approve-button btn btn--clean"
                        @click.stop="open"
                    >{{ labels.CONFIRM }}</a>
                  </template>
                </sign-app>
              </template>
            </div>
          </div>
          <div class="clearfix"></div>
        </li>
        <li class="profile__edit-form-list-item" id="main_UF_SUBSCRIBE">
          <input type="hidden" value="0" name="UF_SUBSCRIBE">
          <input id="profile-edit-subscribe" type="checkbox" class="deletecheck" :value="1"
                 name="UF_SUBSCRIBE" v-model="formData.UF_SUBSCRIBE">
          <label for="profile-edit-subscribe" class="profile__edit-form-subscribe">
            {{ labels.SUBSCRIBE }}
          </label>
        </li>
      </ul>
      <hr>
      <div class="profile__edit-form-btns">
        <button :disabled="!approved || saving"
            class="profile__edit-form-save btn btn--gray-dark" type="submit" name="save"
        >{{ formData.ID > 0 ? labels.MAIN_SAVE : labels.MAIN_EDIT }}</button>
        <button
            class="profile__edit-form-cancel btn btn--gray" type="reset"
        >{{ labels.MAIN_RESET }}</button>
      </div>
    </form>
    <br/>
    <p class="profile__edit-form-message profile__edit-form-message--error">{{ error }}</p>
  </div>
</template>

<script>
import SignApp from '@/signApp.vue'
import axios from 'axios'
import { formatPhone } from '@/helpers/formatPhone'
export default {
  name: 'ProfileEditForm',

  components: { SignApp },

  props: {
    name: String,
    action: String,
    labels: Object,
    user: Object,
    confirmed: Boolean,
  },

  data: (vm) => ({
    formData: {
      ...vm.user,
      UF_SUBSCRIBE: JSON.parse(vm.user.UF_SUBSCRIBE),
    },
    error: '',
    saving: false,
    approved: vm.confirmed,
  }),

  mounted() {
    $.applyDataMask([this.$refs.phoneInput]);
  },

  watch: {
    'formData.PERSONAL_PHONE'(value) {
      if (formatPhone(value) !== this.user.PERSONAL_PHONE) {
        this.approved = false
      } else {
        this.approved = this.confirmed
      }
    }
  },

  methods: {
    async submitForm(event) {
      if (!this.approved) return;
      const form = event.target
      const formData = new FormData(form);

      this.saving = true
      const { data: response } = await axios.post(form.action, formData)

      if (response.status === 'success') {
        location.reload()
      } else if (response.status === 'error') {
        this.error = response.data.message
      }

      this.saving = false
    },
    resetForm() {
      $(this.$refs.phoneInput).unmask();
      Object.assign(this.$data, this.$options.data.apply(this, this))

      this.$nextTick(() => {
        $.applyDataMask([this.$refs.phoneInput]);
      })
    }
  }
}
</script>
