import { render, staticRenderFns } from "./DolyamiWidget.vue?vue&type=template&id=272178fb&scoped=true&"
import script from "./DolyamiWidget.vue?vue&type=script&lang=js&"
export * from "./DolyamiWidget.vue?vue&type=script&lang=js&"
import style0 from "./DolyamiWidget.vue?vue&type=style&index=0&id=272178fb&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "272178fb",
  null
  
)

export default component.exports