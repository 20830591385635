import Vue from 'vue'
import SignApp from './signApp.vue'
import SearchApp from './searchApp.vue'
import BrandsApp from './brandsApp.vue'
import BurgerSearch from '@/components/headerSearch/BurgerSearch.vue'
import AuthActionPopup from '@/components/authActionPopup/AuthActionPopup'
import store from '@/store/index'
import { ModalPlugin } from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import ReminderCountdown from '@/components/reminderCountdown/ReminderCountdown'
import VueCountdown from 'vue-awesome-countdown'
import DolyamiWidget from '@/components/dolyami/DolyamiWidget'
import DolyamiScheme from '@/components/dolyami/DolyamiScheme'
import ModalSignWrapper from '@/components/modalSign/ModalSignWrapper'
import ProfileEditForm from '@/components/ProfileEditForm.vue'
import AuthPageApp from '@/AuthPageApp.vue'

const VueInputMask = require('vue-inputmask').default
Vue.config.productionTip = false
Vue.use(ModalPlugin)
Vue.use(VueInputMask)
Vue.use(Vuelidate)
Vue.use(VueCountdown, 'vac')

/* eslint-disable */
window.initVue = () => {
  const appElements = Array.from(document.querySelectorAll('.vue-app:not(.vue-app-mounted)'));

  for (const appElement of appElements) {
    appElement.classList.add('vue-app-mounted');

    new Vue({
      el: appElement,
      components: {
        SignApp,
        SearchApp,
        BurgerSearch,
        BrandsApp,
        AuthActionPopup,
        ReminderCountdown,
        DolyamiWidget,
        DolyamiScheme,
        ModalSignWrapper,
        ProfileEditForm,
        AuthPageApp,
      },
      store
    });
  }
};

setInterval(() => {
  window.initVue();
}, 500);
