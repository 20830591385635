<template>
  <div class="dolyami-widget" v-b-modal:dolyami-modal>
      <slot>
        <dolyami-logo class="dolyami__logo"/>
        <span>
        4 платежа по <span class="dolyami-widget__amount">{{ payments[0] }}<i class="rouble" itemprop="priceCurrency" content="RUB">a</i></span>
      </span>
        <arrow-icon class="dolyami-widget__arrow"/>
      </slot>
    <b-modal
        id="dolyami-modal"
        modal-class="vue-modal"
        centered
        hide-footer
        hide-header
    >
      <CloseModalBigIcon @click="closeModal" class="modal-close"/>
      <div class="dolyami-popup">
        <dolyami-logo class="dolyami__logo"/>
        <h4 class="h4 dolyami-popup__title">
          Оплатите с Долями<br>только 25% стоимости покупки
        </h4>
        <p>Оставшиеся три платежа спишутся автоматически с шагом в две недели</p>
        <dolyami-scheme class="dolyami-popup__payments" :payments="payments" />
        <small class="dolyami-popup__detailed">Подробную информацию о работе сервиса можно посмотреть на сайте <a href="https://dolyame.ru/" target="_blank">dolyame.ru</a></small>
      </div>
    </b-modal>
  </div>
</template>

<script>
import DolyamiLogo from '@/assets/svg/dolyami-black.svg'
import DolyamiScheme from '@/components/dolyami/DolyamiScheme'
import CloseModalBigIcon from '@/assets/svg/closeModalBigIcon.svg'
import ArrowIcon from '@/assets/svg/arrow.svg'

export default {
  name: 'DolyamiWidget',

  components: {DolyamiLogo, DolyamiScheme, CloseModalBigIcon, ArrowIcon},

  props: {
    payments: {
      type: Array,
      required: true
    }
  },

  methods: {
    closeModal () {
      this.$bvModal.hide('dolyami-modal');
      history.pushState(null, '', ' ');
    }
  }
}
</script>

<style scoped lang="scss">
.dolyami {
  $root: &;
  &-widget {
    padding: 12px;
    margin-top: 1rem;
    --border-color: #C7C9CC;
    border: 0.5px var(--border-color) solid;

    color: #000;
    font-family: Georgia, "Times New Roman", Times, serif;
    display: flex;
    align-items: center;

    font-size: 13px;
    line-height: 24px;

    .rouble {
      color: inherit;
      font-size: inherit;
    }

    #{$root}__logo {
      margin-right: 10px;
    }

    &:hover {
      --border-color: #000;
    }

    &__info {
    }

    &__amount {
      white-space: nowrap;
    }
    &__arrow {
      width: 16px;
      height: 16px;
      margin-left: auto;

      path {
        fill: var(--border-color);
      }
    }
  }

  &-popup {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 2.5rem;
    font-family: Georgia, "Times New Roman", Times, serif;

    @media screen and (max-width:480px) {
      text-align: center;
    }

    &__title {
      margin-bottom: 1rem;
      text-align: center;
      font-size: 2rem;
      line-height: 2.5rem;
    }

    &__payments {
      margin: 1rem 0;
    }

    &__detailed {
      line-height: 1rem;
      color: rgba(100,100,100,1);
    }
  }

  &__logo {
    max-height: 20px;

  }

  &-popup &__logo {
    margin-bottom: 2rem;
  }
}

::v-deep {
  .vue-modal {
    @media (min-width: 768px) {
      .modal-dialog {
        width: 600px;
      }
    }
  }
}
</style>
