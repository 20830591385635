<template>
  <countdown class="reminder-countdown" :class="{ finished }" :end-time="localTime" @finish="finished=true">
    <template #process="{ timeObj }">
      {{ timeObj.h }}:{{ timeObj.m }}:{{ timeObj.s }}
    </template>
    <template v-slot:finish>
       <slot>
         - : - : -
       </slot>
    </template>
  </countdown>
</template>

<script>
export default {
  name: 'ReminderCountdown',

  props: {
    periodEnd: {
      type: Number,
      default: Date.now()
    }
  },

  data: () => ({
    finished: false,
  }),

  computed: {
    localTime () {
      return new Date((+this.periodEnd) * 1000).getTime()
    }
  }
}
</script>
